import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import axios from 'axios';
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapRef } from 'react-map-gl';

import { Geocoder, LoadingBar, SwitchStyle } from 'components';
import { useCurrentUser, useMixpanel } from 'hooks';
import { Button, TooltipButton } from 'tailwind';
import FreeForm from 'views/coverage/draw/FreeForm/FreeForm';
import { DrawTool } from 'views/types';
import { FETCH_250_TILES, GET_MODELLED_CITIES } from './requests';
import { ICity, Overlay, Tiles } from './types';

import Basket from './Purchase/Basket';
import TileSelection from './draw/TileSelection';
import ModelledCities from './modelledCities/ModelledCities';
import Overlays from './overlays/Overlays';

import { ReactComponent as ClearAll } from './icons/ClearAll.svg';
import { ReactComponent as PenFilled } from './icons/PenFilled.svg';
import { ReactComponent as CircleIcon } from './icons/circle.svg';
import { ReactComponent as AddFilled } from './icons/plusFilled.svg';
import { ReactComponent as AddOutlined } from './icons/plusOutlined.svg';
import { ReactComponent as SquareIcon } from './icons/square.svg';

import { useLocation } from 'react-router-dom';
import Polygon from './draw/Polygon';
import './style.css';

const VuMap = lazy(() => import('components/MapProvider/VuMap'));

export const TILE_SIZE = 0.06235264845663324;
export const formatNumber = (num: number) => Number(num.toFixed(2).replace(/\.00$/, ''));

const initialCenter: [number, number] = [-2.13848, 52.7359403];
const initialOverlays: Overlay[] = [
  { id: '250m', title: '250m2', selected: false, minzoom: 11.5 },
  { id: '1km', title: '1km2', selected: false, minzoom: 10.5 },
  { id: 'boroughs', title: 'Boroughs', selected: false, minzoom: 8 },
  { id: 'coverage', title: 'Model Coverage', selected: false, minzoom: 6, area: undefined },
];

const CoverageMap = () => {
  const drawRef = useRef<MapboxDraw>();
  const mapRef = useRef() as { current: MapRef; };

  const { currentUser } = useCurrentUser();
  const { mixpanelTrack } = useMixpanel();

  const { pathname } = useLocation();
  const isPublic = pathname.includes('non/coveragemap');

  //OVERLAYS
  const [selectedOverlays, setSelectedOverlays] = useState<Overlay[]>(initialOverlays);

  // MODELLED CITIES
  const [cities, setCities] = useState<ICity[]>([]);
  const [selectedCity, setSelectedCity] = useState('');

  // PURCHASE POLYGONS
  const [purchase, setPurchase] = useState<boolean>(false);
  const [tool, setTool] = useState<DrawTool>();

  // TILE SELECTION
  const [tiles, setTiles] = useState<Tiles[]>([]);

  // CUSTOM POLYGONS
  const [polygons, setPolygons] = useState<any[]>([]);

  const role = useMemo(() => ({ 'Role': currentUser?.organisation?.me?.role }), [currentUser]);
  const city = useMemo(() => cities.find((({ id }) => `${id}` === `${selectedCity}`))?.city || '', [cities, selectedCity]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(GET_MODELLED_CITIES);
      setCities(data);
    }
    fetchData();
  }, []);

  // UPDATE CUSTOM POLYGONS
  const handleOnUpdate = useCallback(async (e?: {
    features: object[];
    action: string;
  }) => {
    const draw = drawRef?.current;
    if (!draw) return null;

    const polygon = draw.getAll();
    const selectedPolygon = e?.features[0] as any;

    const getTotalTiles = () => {
      return Promise.all(polygon.features.map(async (feature: any) => {
        const { data } = await axios.post(FETCH_250_TILES, JSON.stringify({
          ...feature.geometry,
        }), {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const polygonTiles = data.features?.map((feat: any) => feat.properties?.ID);
        const area = turf.area(feature.geometry);
        const polygonAreaInSkM = turf.convertArea(area, 'meters', 'kilometers');

        return {
          ...feature,
          area: formatNumber(polygonAreaInSkM),
          tiles: polygonTiles,
        };
      }));
    };

    const getPolygons = await getTotalTiles();

    setPolygons(getPolygons);
    setTool(undefined);

    let currentTool: DrawTool = 'Pen Tool';
    if (selectedPolygon?.properties?.isCircle) currentTool = 'Circle Tool';
    if (selectedPolygon?.properties?.square) currentTool = 'Square Tool';
    const totalTiles = getPolygons.map((getPolygon) => getPolygon.tiles).flat(1);

    const mixpanelProps = {
      'Tiles selected': totalTiles,
      'Total area selected': `${TILE_SIZE * totalTiles.length}km²`,
    };

    // handle creation
    if (!e?.action) {
      return mixpanelTrack(`3D model coverage / Tool menu / ${currentTool} / Create pin points`, {
        'Amount of pin points': selectedPolygon?.geometry.coordinates[0].length - 1,
        ...mixpanelProps,
      });
    }

    switch (e?.action) {
      case 'move':
        return mixpanelTrack(`3D model coverage / Tool menu / ${currentTool} / Drag whole shape`, {
          ...mixpanelProps,
        });
      case 'change_coordinates':
        return mixpanelTrack(`3D model coverage / Tool menu / ${currentTool} / Edits pin points`, {
          ...mixpanelProps,
        });
      default: return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool]);

  const onChangeMode = (mode: string) => drawRef?.current?.changeMode(mode);

  const onPurchase = () => {
    setPurchase(prev => !prev);
    setSelectedOverlays(selectedOverlays.map((item) => item.id === '250m' ? { ...item, selected: true } : item));
  };

  // RESET TILES/BASKET
  const resetTiles = () => {
    const hasPolygons = tiles.length > 0 || polygons.length > 0;
    const is250Selected = selectedOverlays.find(({ id }) => id === '250m')?.selected;

    if (hasPolygons && is250Selected) {
      tiles.forEach(tile =>
        mapRef?.current?.setFeatureState({
          source: '250_fill_source_selected',
          sourceLayer: 'default',
          id: tile.id,
        }, { selected: false }),
      );

      setTiles([]);
      setPolygons([]);
      drawRef?.current?.deleteAll();
    }
  };

  // GET NR OF UNIQUE TILES
  const { totalOfUniqueTiles } = useMemo(() => {
    const extractTileIDs = tiles.map(({ id }) => id);
    const uniqueTiles: string[] = Array.from(new Set([...extractTileIDs, ...polygons.map((polygon) => polygon.tiles).flat(1)]));

    return {
      totalOfUniqueTiles: uniqueTiles,
    };
  }, [polygons, tiles]);

  // BACKSPACE / ESCAPE / DELETE
  const onBackSpace = useCallback((ev: KeyboardEvent) => {
    const isPolygonSelected = drawRef.current?.getSelected();
    const featureId = isPolygonSelected?.features[0]?.id as string;

    const deleteKeyboard = ev.code === 'Backspace';
    const escapeButton = ev.code === 'Escape';

    if (escapeButton) {
      setTool(undefined);
    }

    if (featureId && deleteKeyboard && mapRef) {
      mapRef.current.getCanvas().style.cursor = 'default';
      drawRef.current?.delete(featureId);
      handleOnUpdate();
      setTool(undefined);
    }
  }, [handleOnUpdate]);

  useEffect(() => {
    document.addEventListener('keydown', onBackSpace, false);
    return () => { document.removeEventListener('keydown', onBackSpace, false); };
  }, [onBackSpace]);

  // TOOL CLICK
  const handleOnToolClick = useCallback((clickedTool: DrawTool) => {
    const initialZoom: number = mapRef.current?.getZoom();

    mixpanelTrack(`3D model coverage / Tool menu / ${clickedTool}`, { ...role });
    if (clickedTool === tool) {
      onChangeMode('simple_select');
      return setTool(undefined);
    }

    if (initialZoom < initialOverlays[0].minzoom && tool !== 'Clear Shapes')
      mapRef.current?.zoomTo(initialOverlays[0].minzoom);

    return setTool(clickedTool);
  }, [mixpanelTrack, role, tool]);

  // ORDER SUMMARY
  const onSelectCustomShapeOrTile = (id: string, isTile?: boolean) => {
    let center;

    if (!isTile) {
      drawRef?.current?.changeMode('simple_select', { featureIds: [id] });
      const findPolygon = drawRef.current?.getSelected() as any;
      center = findPolygon?.features[0].geometry?.coordinates[0][0];
      mixpanelTrack('Selected a custom shape in basket');
    } else {
      center = tiles.find((tile) => tile.id === id)?.center;
      mixpanelTrack('Selected a tile in basket', { 'Selected tile': id });
    }

    mapRef?.current.flyTo({ center });
  };

  const onDeleteCustomShape = (id: string, isTile?: boolean) => {
    if (!isTile) {
      drawRef?.current?.delete(id);
      handleOnUpdate();
      mixpanelTrack('Removed a shape from the basket');
    } else {
      mixpanelTrack('Removed a tile from the basket', { 'Selected tile': id });
      setTiles(tiles?.filter(tile => tile.id !== id));
      mapRef?.current?.setFeatureState({
        source: '250_fill_source_selected',
        sourceLayer: 'default',
        id,
      }, { selected: false });
    }
  };

  // CLEAR TILES
  const handleClearShapes = () => {
    const shapesCount = drawRef.current?.getAll()?.features?.length;
    mixpanelTrack('3D model coverage / Tool menu / Clear tool', {
      role,
      'Amount of selections cleared': shapesCount,
    });

    resetTiles();
  };

  return (
    <div className='relative w-full h-screen text-base text-content'>
      <SwitchStyle showBtn showCoverage>
        {(style: string) => (
          <Suspense fallback={<LoadingBar />}>
            <VuMap
              ref={mapRef}
              center={initialCenter}
              zoom={6}
              style={style}
              customControls
              controlsClass='m-3'
            >
              <Geocoder />
              <FreeForm ref={drawRef} onUpdate={handleOnUpdate} />

              {/* DRAWING TOOLS */}
              {purchase && (
                <div data-tour-key="3D-drawing-tools" className='absolute left-[370px] top-3 flex gap-1'>
                  <div className='flex gap-1'>
                    {/* Tile selection */}
                    <TooltipButton
                      selected={tool === 'Tile Tool'}
                      description={<div>250m<sup>2</sup> tile selection</div>}
                      onClick={() => { onChangeMode('simple_select'); handleOnToolClick('Tile Tool'); }}
                    >
                      {tool === 'Tile Tool' ? <AddFilled /> : <AddOutlined />}
                    </TooltipButton>

                    {/*Pen tool */}
                    <TooltipButton
                      selected={tool === 'Pen Tool'}
                      description={<div>Pen selection</div>}
                      onClick={() => { onChangeMode('draw_polygon'); handleOnToolClick('Pen Tool'); }}
                    >
                      <PenFilled />
                    </TooltipButton>

                    {/*Circle tool */}
                    <TooltipButton
                      selected={tool === 'Circle Tool'}
                      description={<div>Circle area selection</div>}
                      onClick={() => { onChangeMode('drag_circle'); handleOnToolClick('Circle Tool'); }}
                    >
                      <CircleIcon />
                    </TooltipButton>

                    {/*Square tool */}
                    <TooltipButton
                      selected={tool === 'Square Tool'}
                      description={<div>Square area selection</div>}
                      onClick={() => { onChangeMode('drag_rectangle'); handleOnToolClick('Square Tool'); }}
                    >
                      <SquareIcon />
                    </TooltipButton>

                    {/*Clear all shapes tool */}
                    <TooltipButton
                      selected={tool === 'Clear Shapes'}
                      description={<div>Clear selection</div>}
                      onClick={handleClearShapes}
                    >
                      <ClearAll />
                    </TooltipButton>
                  </div>

                  <TileSelection
                    tiles={tiles}
                    setTiles={setTiles}
                    selected={tool === 'Tile Tool'}
                  />
                  <Polygon />
                </div>
              )}

              {/* BASKET */}
              <div className='absolute flex gap-1 font-sans right-3 top-3'>
                {!purchase ? (
                  <div className='bg-white rounded p-1.2 w-200 text-base flex flex-col gap-1.2' data-tour-key="purchase-3D-models">
                    <p>Purchase areas of our 3D models or request areas to be modelled.</p>
                    <Button size='md' variant='contained' onClick={onPurchase}>Start quote</Button>
                  </div>
                ) : (
                  <Basket
                    uniqueTiles={totalOfUniqueTiles}
                    summary={{ tiles, polygons }}
                    onResetTiles={resetTiles}
                    onSelect={onSelectCustomShapeOrTile}
                    onDelete={onDeleteCustomShape}
                  />
                )}
              </div>

              {/* LEFT PANEL */}
              <div className={`
                relative flex flex-col items-center p-3 mb-3 ml-3 overflow-auto font-sans bg-white rounded w-330 mt-9 
                ${isPublic ? 'h-coveragePublic' : 'h-coveragePanel'}
              `}>
                <ModelledCities cities={cities} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
                <Overlays
                  resetTiles={() => {
                    setPurchase(false);
                    setTool(undefined);
                    resetTiles();
                  }}
                  selectedOverlays={selectedOverlays}
                  setSelectedOverlays={setSelectedOverlays}
                  selectedCity={city}
                />
              </div>
            </VuMap>
          </Suspense>
        )}
      </SwitchStyle>
    </div>
  );
};

export default CoverageMap;