import { useQuery } from '@apollo/client';
import { FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';
import { InfoIcon } from '@vucity/design_system';
import { Licenses } from 'components';
import { useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { DeepMap, FieldError, FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { IndividualInvites } from 'tailwind';
import { License, Project, ProjectInput, ProjectMemberInput } from 'types/graphql';
import GET_ORG_PROJECTS from 'views/organisation/overview/apollo';
import { FormControl } from './CreateForm.style';

interface IFormProps {
  watch: UseFormWatch<ProjectInput>;
  allLicences: License[];
  onLicenceChange: (e: string) => void;
  setValue: UseFormSetValue<ProjectInput>;
  formData: {
    register: UseFormRegister<ProjectInput>;
    errors: DeepMap<FieldValues, FieldError>;
    setError: UseFormSetError<any>;
    clearErrors: UseFormClearErrors<any>;
  };
  isDuplicateName: boolean;
  setIsDuplicateName: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateForm = ({
  watch, allLicences, onLicenceChange, setValue, formData, isDuplicateName, setIsDuplicateName,
}: IFormProps) => {
  const { register, errors, setError, clearErrors } = formData;

  const [members, setMembers] = useState<ProjectMemberInput[]>(watch('members') || [{ email: '', role: 'ADMIN' }]);

  const { mixpanelTrack } = useMixpanel();

  const onUpdateMembers = (updatedMembers: ProjectMemberInput[]) => {
    setMembers(updatedMembers);
    setValue('members', updatedMembers);
  };

  const { data } = useQuery(GET_ORG_PROJECTS);

  const {
    currentUser: { organisation },
  } = data ?? {
    currentUser: {
      organisation: {
        trialAvailable: false,
        subscriptions: [],
      },
      lastLogin: null,
    },
  };

  const orgProjectNames = !!data && organisation?.projects?.map((org: Project) => org.name);
  const hasDuplicateName = (value: string) =>
    orgProjectNames?.some((name: string) => name.toLowerCase() === value.toLowerCase());

  const isValidInput = (value: string) => /^[a-zA-Z0-9 _\-:]+$/.test(value);
  
  return (
    <div className="space-y-2">
      <FormControl fullWidth>
        <InputLabel htmlFor='name' error={!!errors.name}>
          Name of project*
        </InputLabel>

        <OutlinedInput
          id="name"
          labelWidth={0}
          placeholder="Name"
          fullWidth
          {...register('name', {
            required: true,
            validate: (value) => isValidInput(value) || 'Invalid characters in input',
            onChange: (e) => {
              mixpanelTrack('Name of project updated', { 'Project name': e.target.value });
              if (hasDuplicateName(e.target.value)) {
                setIsDuplicateName(!isDuplicateName);
              } else {
                setIsDuplicateName(false);
              }
            },
            onBlur: (e) => {
              setValue('name', e.target.value, { shouldValidate: true });
            },
          })}
          error={!!errors.name}
        />
        {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
        {isDuplicateName && <FormHelperText className="text-black"><InfoIcon className="w-[14px] h-[14px] mr-1 text-warning" /> A project in your organisation already has this name</FormHelperText>}
      </FormControl>

      <FormControl fullWidth>
        <InputLabel htmlFor='location' error={!!errors.location}>
          Select location*
        </InputLabel>

        <Licenses
          licenses={allLicences}
          selectedLicense={watch('location')?.licenseId}
          onLicenseChange={onLicenceChange}
        />
        {errors.location
          && <div className='mt-1 text-xs leading-4 text-error' dangerouslySetInnerHTML={{ __html: errors.location.message }} />
        }
      </FormControl>

      <FormControl fullWidth className='mt-1'>
        <InputLabel htmlFor='members'>
          Add <span className='font-medium'>admin(s)</span> to your project
        </InputLabel>
      </FormControl>

      <div className='flex flex-col gap-1 px-2'>
        <IndividualInvites
          members={members}
          defaultRole='ADMIN'
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          onUpdate={onUpdateMembers}
          mixpanelEvent='createFromDrive'
        />
      </div>
    </div>
  );
};

export default CreateForm;
