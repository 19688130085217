import { useQuery } from '@apollo/client';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';
import { InfoIcon } from '@vucity/design_system';
import React from 'react';
import { DeepMap, FieldError, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Project, ProjectInput } from 'types/graphql';
import GET_ORG_PROJECTS from '../../../organisation/overview/apollo';

interface Step1Props {
  setValue: UseFormSetValue<ProjectInput>;
  errors: DeepMap<FieldValues, FieldError>;
  register: UseFormRegister<ProjectInput>;
  isDuplicateName: boolean;
  setIsDuplicateName: React.Dispatch<React.SetStateAction<boolean>>;
  isDuplicateReference: boolean;
  setIsDuplicateReference: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step1 = ({ setValue, errors, register, isDuplicateName, setIsDuplicateName, isDuplicateReference, setIsDuplicateReference }: Step1Props) => {
  const { data } = useQuery(GET_ORG_PROJECTS);

  const {
    currentUser: { organisation },
  } = data ?? {
    currentUser: {
      organisation: {
        trialAvailable: false,
        subscriptions: [],
      },
      lastLogin: null,
    },
  };

  const orgProjectNames = !!data && (organisation?.projects?.map((org: Project) => org?.name) || []);
  const orgProjectReferences = !!data && (organisation?.projects?.map((org: Project) => org?.reference) || []);


  const hasDuplicateName = (value: string) =>
    orgProjectNames?.some((name: string) => name?.toLowerCase() === value?.toLowerCase());


  const hasDuplicateReference = (value: string) =>
    orgProjectReferences?.some((reference: string) =>
      reference?.toLowerCase() === value?.toLowerCase(),
    );

  const isValidInput = (value: string) => /^[a-zA-Z0-9 _\-:]+$/.test(value);

  return (
    <section>
      <h3>Create your new project</h3>
      <p className='mt-1 text-lg'>
        Creating a new project will use up <span className='font-medium'>1 project space</span>
      </p>

      <article className='flex flex-col gap-3 mt-3'>
        <div className='flex gap-3'>
          <FormControl fullWidth>
            <InputLabel htmlFor="name" error={!!errors.name}>Name of project*</InputLabel>
            <OutlinedInput
              fullWidth
              labelWidth={0}
              placeholder="Name"
              {...register('name', {
                required: true,
                validate: (value) => isValidInput(value) || 'Invalid characters in input',
                onChange: (e) => {
                  setValue('name', e.target.value, { shouldValidate: true });
                  if (hasDuplicateName(e.target.value)) {
                    setIsDuplicateName(!isDuplicateName);
                  } else {
                    setIsDuplicateName(false);
                  }
                },
              })}
              error={!!errors.name}
            />
            {errors.name && <FormHelperText className="text-error"><InfoIcon className="w-[14px] h-[14px] mr-1 text-error" />{errors.name.message}</FormHelperText>}
            {isDuplicateName && <FormHelperText className="text-black"><InfoIcon className="w-[14px] h-[14px] mr-1 text-warning" /> A project in your organisation already has this name</FormHelperText>}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="reference">Project reference code</InputLabel>
            <OutlinedInput
              fullWidth
              labelWidth={0}
              placeholder="Code"
              {...register('reference', {
                onChange: (e) => {
                  setValue('reference', e.target.value);
                  if (!e.target.value) return setIsDuplicateReference(false);

                  if (hasDuplicateReference(e.target.value)) {
                    setIsDuplicateReference(!isDuplicateReference);
                  } else {
                    setIsDuplicateReference(false);
                  }
                },
              })}
            />
            {isDuplicateReference && <FormHelperText className="text-black"><InfoIcon className="w-[14px] h-[14px] mr-1 text-warning" /> A project in your organisation already has this reference</FormHelperText>}
          </FormControl>
        </div>

        <FormControl fullWidth>
          <InputLabel htmlFor="description">Description</InputLabel>
          <OutlinedInput
            fullWidth
            placeholder="Max 300 characters"
            multiline
            rows={5}
            inputProps={{ maxLength: 300 }}
            {...register('description', {
              onChange: (e) => setValue('description', e.target.value),
            })}
          />
        </FormControl>
      </article>
    </section>
  );
};

export default Step1;
